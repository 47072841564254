<template>
    <Details
        :title="isAdminDetails ? 'Customer Admin' : 'Customer User'"
        resource="customerProfiles"
        base-path="customers.users.index"
        :request-params="{customerId: customerId}"
        routeIdParam="userId"
        ref="details"
        :title-template="isAdminDetails ? 'admin {fullName}' : 'user {fullName}'"
        breadcrumb-template="{fullName} details"
    >
        <template v-slot:default="detailsProps">
            <div class="h-full">
                <DetailsBar class="relative mb-10">
                    <div class="form-section-title" :style="{ paddingTop: 0 }">user details</div>
                    <div class="form-row">
                        <div class="user-details-field w-1/5">
                            <label class="form-label">first name</label>
                            <div>{{ detailsProps.record.firstName }}</div>
                        </div>
                        <div class="user-details-field w-1/5">
                            <label class="form-label">last name</label>
                            <div>{{ detailsProps.record.lastName }}</div>
                        </div>
                        <div class="user-details-field w-1/5">
                            <label class="form-label">status</label>
                            <StatusLabel :status="detailsProps.record.status"/>
                        </div>
                        <div class="user-details-field w-1/5">
                            <label class="form-label">last login</label>
                            <div>{{ lastLogin }}</div>
                        </div>
                        <div class="user-details-field w-1/5">
                            <label class="form-label">forced mfa setup</label>
                            <div>{{ detailsProps.record.mfaRequired ? 'Enabled' : 'Disabled' }}</div>
                        </div>
                    </div>
                    <div class="form-row">
                        <div class="user-details-field w-1/5">
                            <label class="form-label">work phone number</label>
                            <div>{{ detailsProps.record.workPhoneNumber || '-' }}</div>
                        </div>
                        <div class="user-details-field w-1/5">
                            <label class="form-label">sms phone number</label>
                            <div>{{ detailsProps.record.smsPhoneNumber || '-' }}</div>
                        </div>
                        <div class="user-details-field w-1/5">
                            <label class="form-label">personal phone number</label>
                            <div>{{ detailsProps.record.personalPhoneNumber || '-' }}</div>
                        </div>
                        <div class="user-details-field w-2/5">
                            <label class="form-label">email address</label>
                            <div>{{ detailsProps.record.email }}</div>
                        </div>
                    </div>
                    <Authorize :has-role="['SUPER_ADMIN', 'CUSTOMER_ADMIN']">
                        <div v-if="!detailsProps.record.deletedAt" class="absolute top-3 right-3">
                            <button @click="() => editUser(0)">
                                <icon name="pencil" class="edit-icon"/>
                            </button>
                        </div>
                    </Authorize>
                </DetailsBar>

                <div class="relative mb-8 lowercase identity-list">
                    <div class="form-section-title">authentication information</div>
                    <List
                        :columns="identityColumns"
                        :items="identities"
                        :css="{ bodyRowClass: 'row' }"
                    ></List>
                </div>

                <div class="relative mb-8 lowercase">
                    <div class="form-section-title">general quext permissions</div>
                    <div class="text-sm text-black">
                        <span class="font-600">{{ authAppName }}:</span> {{ generalRole }}
                    </div>
                    <Authorize :has-role="['SUPER_ADMIN', 'CUSTOMER_ADMIN']">
                        <div v-if="!detailsProps.record.deletedAt" class="absolute top-3 right-3">
                            <button @click="() => editUser(2)">
                                <icon name="pencil" class="edit-icon"/>
                            </button>
                        </div>
                    </Authorize>
                </div>

                <div class="relative">
                    <div class="form-section-title">communities and permissions</div>
                    <InfoText v-if="licensedApps.length === 0" class="my-4">
                        no applications are licensed to operate in the following communities or all authorized
                        applications are user agnostic
                    </InfoText>
                    <div class="relative">
                        <AuthList
                            title="communities"
                            resource=""
                            class="community-list"
                            :data="communities"
                            :fields="fields"
                            track-by="communityId"
                        ></AuthList>
                    </div>
                    <Authorize :has-role="['SUPER_ADMIN', 'CUSTOMER_ADMIN']">
                        <div v-if="!detailsProps.record.deletedAt" class="absolute top-3 right-3">
                            <button @click="() => editUser(1)">
                                <icon name="pencil" class="edit-icon"/>
                            </button>
                        </div>
                    </Authorize>
                </div>
            </div>
        </template>
    </Details>
</template>

<script>
import { uniqBy } from "lodash-es";
import moment from 'moment';
import ActionsMixin from "@/mixins/ActionsMixin";
import ModalNavigation from '@/mixins/ModalNavigation';
import NotifyMixin from "@/mixins/NotifyMixin";
import Icon from "@/components/ui/Icon";
import List from "@/components/list/List";
import Authorize from "@/components/auth/Authorize";
import Details from '@/components/auth/details/Details';
import DetailsBar from "@/components/auth/details/DetailsBar";
import StatusLabel from "@/components/auth/StatusLabel";
import AuthList from '@/components/auth/list/List';
import CheckField from "@/components/auth/list/fields/CheckField";
import { AUTH_NAME, BUNDLE_TYPES, ROLES, STATUS } from '@/components/auth/constants';
import InfoText from "@/components/ui/InfoText";

export default {
    name: 'UserDetails',
    mixins: [ModalNavigation, NotifyMixin, ActionsMixin],
    components: {
        InfoText,
        Authorize,
        AuthList,
        Details,
        DetailsBar,
        Icon,
        List,
        StatusLabel,
    },
    data() {
        return {
            authAppName: AUTH_NAME,
            authRoles: [],
            customerCommunities: [],
            licensedApps: [],
            record: {},
            identityColumns: [
                {
                    name: 'email',
                    title: 'email',
                    class: 'w-1/3 identity-cell',
                },
                {
                    name: 'phone',
                    title: 'phone number',
                    class: 'w-1/3 identity-cell',
                },
                {
                    name: 'cognitoUserId',
                    title: 'cognito user id',
                    class: 'w-1/3 identity-cell',
                },
            ],
        };
    },
    computed: {
        communities() {
            const {appRoles = [], communities = [], metaRoles = []} = this.record;
            const isGlobal = appRoles[0]?.roles.find(r => r.global) || metaRoles[0]?.roles.find(r => r.isGlobal);
            const selectedCommunities = isGlobal ? this.customerCommunities : uniqBy(communities, 'communityId');

            return this.customerCommunities.map(({communityId, communityName}) => ({
                communityId,
                communityName,
                apps: this.licensedApps.reduce((acc, {appId, communities: licensedCommunities}) => {
                    acc[appId] = this.roles[appId] && licensedCommunities.find(c => c.communityId === communityId)
                        && selectedCommunities.find(c => c.communityId === communityId);
                    return acc;
                }, {}),
            }));
        },
        customerId: function () {
            return this.$route.params.customerId;
        },
        identities() {
            return this.record.identities || [];
        },
        isAdminDetails: function () {
            return this.$route.path.includes('admins');
        },
        generalRole() {
            const {role} = this.record;
            if (role === ROLES.USER) {
                return 'not authorized';
            }
            const userRole = this.authRoles.find(r => r.value === role)?.name ?? role;
            return `${userRole} role`;
        },
        fields() {
            const licensingFields = this.licensedApps.map(({appId, appName}) => ({
                name: CheckField,
                title: () => `<span class="flex flex-col text-center">
              <span class="font-600">${appName}</span>
              <span class="text-sm">${this.roles[appId]?.appRoleName || 'no role'}</span>
            </span>`,
                titleClass: 'whitespace-no-wrap font-normal',
                switch: {
                    containerClass: 'flex justify-center',
                    fieldName: `apps[${appId}]`,
                    readonly: true,
                },
            }));

            return [
                {
                    name: 'communityName',
                    title: 'community name',
                    dataClass: 'whitespace-no-wrap',
                    titleClass: 'font-normal',
                },
                ...licensingFields,
            ];
        },
        lastLogin() {
            return this.record.lastLogin ? moment(this.record.lastLogin).format('MM/DD/YYYY hh:mm a') : 'n/a';
        },
        roles() {
            const {appRoles = [], metaRoles = []} = this.record;
            return {
                ...appRoles.reduce((acc, item) => ({
                    ...acc,
                    [item.appId]: {appRoleId: item.roles[0].appRoleId, appRoleName: item.roles[0].alias},
                }), {}),
                ...metaRoles.reduce((acc, item) => ({
                    ...acc,
                    [item.bundleId]: {appRoleId: item.metaRoleId, appRoleName: item.metaRoleName},
                }), {}),
            };
        },
    },
    methods: {
        editUser(step) {
            this.$router.push({
                name: this.$route.name.replace('details', 'edit'),
                params: this.$route.params,
                query: {'wizard': step},
            });
        },

        fetchAuthRoles() {
            this.$authDataProvider.getList('customerAuthRoles')
                .then(data => {
                    this.authRoles = data;
                })
                .catch(error => this.notifyError(error.message))
        },

        fetchCommunities() {
            this.$authDataProvider.getList('customerCommunities', {
                customerId: this.customerId,
                size: 999,
                sort: 'name',
                nonDeleted: true,
            })
                .then(({content}) => {
                    this.customerCommunities = content.map(({id, name}) => ({communityId: id, communityName: name}));
                })
                .catch(error => this.notifyError(error.message));
        },

        fetchLicensedApps() {
            Promise.all([
                this.$authDataProvider.getList('bundles', {
                    page: 0,
                    size: 999,
                    nonDeleted: true,
                }),
                this.$authDataProvider.getOne('customers', {id: this.customerId}),
            ]).then(([bundles, customer]) => {
                const bundleMap = bundles.content.reduce((acc, item) => ({
                    ...acc,
                    [item.id]: item,
                }), {});

                this.licensedApps = customer.licenses
                    .map(({bundleId, bundleName, communities = []}) => {
                        const {appId, apps = [], metaroles = [], type} = bundleMap[bundleId] || {};
                        const roles = type === BUNDLE_TYPES.STAND_ALONE ? apps[0].roles : metaroles;
                        return {
                            appId: appId || bundleId,
                            appName: bundleName,
                            communities,
                            hasRoles: roles?.length > 0,
                        }
                    })
                    .filter((a) => a.hasRoles);
            }).catch(error => this.notifyError(error.message));
        },

        resetPassword() {
            this.$authDataProvider.create('resetCustomerUserPassword', {
                customerId: this.customerId,
                profileId: this.record.profileId,
            })
                .then(() => this.notifySuccess('password has been reset'))
                .catch(error => this.notifyError(error.message));
        },

        resetMfa() {
            this.$authDataProvider.create('resetCustomerUserMfa', {
                customerId: this.customerId,
                profileId: this.record.profileId,
            })
                .then(() => this.notifySuccess('MFA setup has been reset'))
                .catch(error => this.notifyError(error.message));
        },

        deleteUser() {
            this.$authDataProvider.delete('customerUsers', {id: this.$route.params.userId, customerId: this.customerId})
                .then(() => {
                    this.notifySuccess(`user ${this.record.firstName} ${this.record.lastName} has been deleted`);
                    this.close();
                })
                .catch(error => this.notifyError(error.message))
        },
    },
    mounted() {
        Promise.all([
            this.fetchAuthRoles(),
            this.fetchCommunities(),
            this.fetchLicensedApps(),
        ]);

        this.$watch('$refs.details.record', function (record) {
            const {lastLogin, deletedAt, active, mfaRequired, status} = record;
            this.record = record;

            if (!deletedAt && status.toLowerCase() === STATUS.INVITED) {
                this.addActionToStore({
                    routeName: this.$route.name,
                    item: {
                        id: 'resend-user-invitation',
                        title: 'resend invitation',
                        routeName: this.$route.name.replace('details', 'invite.index'),
                        params: {customerId: '{customerId}', userId: '{userId}'},
                        dynamic: true,
                    },
                });
            }

            if (active && lastLogin && !deletedAt) {
                this.addConfirmAction(
                    {
                        id: 'auth-reset-password',
                        title: "reset password",
                        showConfirmation: true,
                        confirmationMessage: `reset password for ${record.firstName} ${record.lastName}?`,
                        confirmationType: 'success',
                        confirmBtnText: 'yes',
                        cancelBtnText: 'no',
                    },
                    this.resetPassword,
                );
            }

            if (mfaRequired && active && lastLogin && !deletedAt) {
                this.addConfirmAction(
                    {
                        id: 'auth-reset-mfa',
                        title: "reset mfa setup",
                        showConfirmation: true,
                        confirmationMessage: `reset multi factor authentication setup for ${record.firstName} ${record.lastName}?`,
                        confirmationType: 'success',
                        confirmBtnText: 'yes',
                        cancelBtnText: 'no',
                    },
                    this.resetMfa,
                );
            }

            if (!deletedAt) {
                this.addConfirmAction(
                    {
                        id: 'auth-delete-user',
                        title: this.isAdminDetails ? "delete admin" : "delete user",
                        showConfirmation: true,
                        confirmationMessage: 'are you sure you want to delete this user?',
                        confirmBtnText: 'yes',
                        cancelBtnText: 'no',
                        confirmationType: 'warning',
                    },
                    this.deleteUser,
                )
            }
        })
    },
};
</script>

<style scoped>
.user-details-field {
    padding-left: 1rem;
    padding-right: 1rem;
    font-size: 0.8125rem;

&
:first-child {
    padding-left: 0;
}

&
:last-child {
    padding-right: 0;
}

}

.edit-icon {
    @apply w-4 h-4 cursor-pointer;
}

.community-list :deep(.vuetable th:first-child),
.community-list :deep(.vuetable td:first-child) {
    position: sticky;
    left: 0;
    background-color: inherit;
    z-index: 11;
}

.identity-list :deep(.row > .identity-cell) {
    @apply text-xs break-all;
}
</style>
