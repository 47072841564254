<template>
  <div class="border border-gray-300 bg-gray-50 p-6">
    <!-- @slot Content inside the section -->
    <slot />
  </div>
</template>

<script>
  export default {
    name: "DetailsBar"
  }
</script>
